import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { HeadingXL, Layout, SEO, TextBody } from '../components';
import { Button } from '@chakra-ui/button';
import { Center } from '@chakra-ui/layout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Página no encontrada" />
      <Layout>
        <Wrapper>
          <HeadingXL>Página no encontrada</HeadingXL>
          <TextBody style={{ textAlign: 'center' }}>
            Esta página no existe
            <span role="img" aria-label="duh">
              😓
            </span>
          </TextBody>
          <Center>
            <Link to="/">
              <Button colorScheme="violet" size="lg">
                Ir al inicio
              </Button>
            </Link>
          </Center>
        </Wrapper>
      </Layout>
    </>
  );
};

export default NotFoundPage;
